import { render, staticRenderFns } from "./nav.vue?vue&type=template&id=8f67e272&scoped=true&"
import script from "./nav.vue?vue&type=script&lang=js&"
export * from "./nav.vue?vue&type=script&lang=js&"
import style0 from "./nav.vue?vue&type=style&index=0&id=8f67e272&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8f67e272",
  null
  
)

export default component.exports